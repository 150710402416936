const Login = () => import('@pages/Login.vue')
const Register = () => import('@pages/Register.vue')
const ForgotPassword = () => import('@pages/ForgotPassword.vue')
const ReactivateAccount = () => import('@pages/ReactivateAccount.vue')
const Main = () => import('@pages/Main.vue')
const Navbar = () => import('@components/Navbar.vue')
const Sidebar = () => import('@components/Sidebar.vue')
const Header = () => import('@components/Header.vue')
const SuccessChangePassword = () => import('@pages/SuccessChangePassword.vue')
const SuccessSentEmail = () => import('@pages/SuccessSentEmail.vue')
const AssignVendingDinamis = () => import('@pages/AssignVendingDinamis')

const mainPages = require('./main-pages')

export default {
    routes: [
        {
            path: '',
            component: {
                template: '<router-view></router-view>'
            },
            children: [
                {
                    path: '',
                    component: Main,
                    children: [
                        {
                            path: '',
                            components: {
                                default: {
                                    template: '<router-view></router-view>'
                                },
                                sidebar: Sidebar,
                                navbar: Navbar,
                                header: Header,
                                // footer: Footer
                            },
                            children: mainPages
                        }
                    ]
                }, {
                    path: '/login',
                    name: 'login',
                    component: Login
                }, {
                    path: '/register',
                    name: 'register',
                    component: Register
                }, {
                    path: '/forgot-password',
                    name: 'forgot_password',
                    component: ForgotPassword
                }, {
                    path: '/reaktivasi-akun',
                    name: 'reaktivasi_akun',
                    component: ReactivateAccount
                }, {
                    path: '/success-change-password',
                    name: 'success_change_password',
                    component: SuccessChangePassword
                },
                {
                    path: '/success-sent-email',
                    name: 'success_sent_email',
                    component: SuccessSentEmail
                },
                {
                    path: '/AssignVendingDinamis',
                    name: 'AssignVendingDinamis',
                    component: AssignVendingDinamis
                }
            ]
        }
    ]
}
